import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Grid, Box } from 'react-raster'

import SEO from '../seo'

import Image from 'components/image'
import Video from 'components/video'
import gridProps from 'layouts/gridProps'
import { useTransitionStore } from 'gatsby-plugin-transitions'
import useStore from 'state'
import useWindowSize from 'utils/useWindowSize'
// import useBodyHeight from 'utils/useBodyHeight'

const Work = ({ data: { seo, works }, location }) => {
  const [{ hasEntered }, dispatch] = useTransitionStore()
  const y = useStore(state => state.y)
  const [, windowHeight] = useWindowSize()
  // const bodyHeight = useBodyHeight()

  useEffect(() => {
    function onWorkScroll () {
      if (!hasEntered) return
      if (windowHeight + window.pageYOffset >= document.body.offsetHeight - windowHeight * 0.5) {
        window.removeEventListener('scroll', onWorkScroll)
        dispatch({
          type: 'NAVIGATE',
          to: '/',
          enter: {
            opacity: 0,
            transform: 'translate3d(0, 100vh, 0)'
          },
          leave: {
            opacity: 0,
            transform: 'translate3d(0, -100vh, 0)'
          },
          mode: 'successive',
          y
        })
      }
    }
    window.addEventListener('scroll', onWorkScroll)
    return () => window.removeEventListener('scroll', onWorkScroll)
  }, [hasEntered, windowHeight])

  return (
    <>
      <SEO
        title={`${works.title} | ${seo.title}`}
        description={works.text.replace(/(<([^>]+)>)/ig, '')}
        url={location.url}
      />
      <Grid
        {...gridProps}
        top={'10vh'}
        bottom={'100vh'}
        style={`min-height: 125vh;`}
      >
        {works.contentWork.map(({ url, videoDesktop, videoTablet, videoMobile, sizes, width, height, alt }) => (
          <Box cols={12} key={url}>
            {videoDesktop
              ? (
                <Video
                  className='work__video'
                  srcDesktop={videoDesktop}
                  srcTablet={videoTablet}
                  srcMobile={videoMobile}
                // ratio='1:1.785'
                />
              ) : (
                <Image
                  className='work__image'
                  sizes={sizes}
                  alt={alt}
                  width={width}
                  height={height}
                  style={`
                  img {
                    transition: opacity 0.5s;
                    opacity: 0;
                  }
                  &.loaded img {
                    opacity: 1;
                  }
                  background-color: rgba(0,0,0,0.1)
                `}
                />
              )
            }
          </Box>
        ))}
        <Box cols={12}>
          <Grid
            colspan={12}
            gutterX={['2vw', '1.5vw', '1vw']}
            style={`width: 100%;`}
          >
            <Box cols={[12, 12, 12, 4]}>
              <h2 className='teaser__text size--0'>{works.title}</h2>
            </Box>
            <Box cols={[11, 11, 11, 8]} left={[1, 1, 1, 0]}>
              <div
                className='teaser__text size--0'
                dangerouslySetInnerHTML={{ __html: works.text }}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  )
}

export default Work

export const query = graphql`
  query($url: String) {
    seo {
      title
      description
    }
    works(url: {eq: $url }) {
      url
      title
      text
      description
      contentWork {
        type
        videoDesktop
        videoTablet
        videoMobile
        url
        alt
        sizes
        width
        height
        modified
        # local {
        #   childImageSharp {
        #     fluid(maxWidth: 2400) {
        #       ...GatsbyImageSharpFluid_withWebp_noBase64
        #     }
        #   }
        # }
      }
    }
  }
`
