import { useState, useEffect } from 'react'

const useSrc = (srcDesktop, srcTablet, srcMobile) => {
  const [src, setSrc] = useState('')
  useEffect(() => {
    const w = window.innerWidth
    if (w <= 812) setSrc(srcMobile)
    else if (w <= 1024) setSrc(srcTablet)
    else setSrc(srcDesktop)
  }, [])
  return src || srcMobile || srcTablet || srcDesktop
}

export default useSrc
