import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// import ReactPlayer from 'react-player'
import useSrc from './hooks/useSrc'
import { useInView } from 'react-intersection-observer'

export function getPaddingTop (ratio, width, height) {
  const defaultRatio = ratio || '1:2'
  const ratioArray = defaultRatio.split(':')
  return (ratioArray[0] / ratioArray[1]) * 100
}

const Video = ({ srcDesktop, srcTablet, srcMobile, ratio }) => {
  const src = useSrc(srcDesktop, srcTablet, srcMobile)
  const videoRef = useRef()
  const [paddingTop, setPaddingTop] = useState(() => getPaddingTop(ratio))

  const [wrapperRef, videoLoad] = useInView({
    threshold: 0,
    rootMargin: '500px',
    triggerOnce: true
  })

  const [innerRef, play] = useInView({
    threshold: 0,
    rootMargin: '0px',
    triggerOnce: true
  })

  const [canPlay, setCanPlay] = useState(false)
  function onCanPlay () {
    setCanPlay(true)
  }

  useEffect(() => {
    if (!ratio && canPlay) {
      const { videoWidth, videoHeight } = videoRef.current
      setPaddingTop((videoHeight / videoWidth) * 100)
    }
  }, [canPlay])

  useEffect(() => {
    if (!videoRef.current || !canPlay) return
    if (play) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [canPlay, play])

  return (
    <div
      ref={wrapperRef}
      className='video-wrapper'
      style={{
        position: 'relative',
        width: '100%',
        height: '0',
        paddingTop: `${paddingTop}%`,
        overflow: 'hidden',
        background: 'rgba(0,0,0,0.1)'
      }}
    >
      <div
        ref={innerRef}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: '100%'
        }}
      >
        <video
          src={src}
          ref={videoRef}
          preload={videoLoad ? 'auto' : 'none'}
          loop
          muted
          playsInline
          onLoadedMetadata={onCanPlay}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }}
        />
      </div>
    </div>
  )
}

Video.propTypes = {
  srcDesktop: PropTypes.string,
  srcMobile: PropTypes.string,
  ratio: PropTypes.string
}
Video.defaultProps = {
  srcDesktop: '',
  srcMobile: '',
  ratio: ''
}

export default Video
